const addressFields = [
    "street",
    "number",
    "city",
    "zip",
    "state",
    "country"
];

const AddressMixin = {
    methods: {
        stringifyAddress: (address) => {
            if (address) {
                return [
                    `${address.street ?? ""} ${address.number ?? ""}`,
                    (address.city),
                    (address.zip),
                    (address.state),
                    (address.country)
                ].filter(el => el != null && el !== "").join(", ")
            }
        },
    }
}

export default AddressMixin
export {addressFields}
