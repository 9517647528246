<template>
  <el-dialog v-if="fileList" v-model="showDialog" :close-on-click-modal="false">
    <template #title>
      <h3 style="margin:0"> Anteprima campi csv </h3>
      <p> Visualizzazione delle prime 5 righe del file</p>
      <el-alert show-icon type="info" title="Controllare la correttezza delle intestazioni e del formato dei campi prima di proseguire con l'upload" ></el-alert>

    </template>
    <el-tabs v-model="activeName" v-if="showDialog">
      <el-tab-pane v-for="(file, i) in fileList" :key="file" :label="file.name" :name="i+''">
        <el-table  fit v-if="csvObjects[i]" :data="csvObjects[i]" >
          <el-table-column :key="header" v-for="header in csvHeaders[i]" :prop="header" :label="header" min-width="100%"> </el-table-column>
        </el-table>
        <p v-if="csvRemainingRecords[i]>0"> altre {{csvRemainingRecords[i]}} righe caricate in memoria </p>
      </el-tab-pane>
    </el-tabs>


    <template #footer>
    <span class="dialog-footer">
      <el-button @click="showDialog=false">Annulla</el-button>
      <el-button type="primary" @click="confirmDialog"> <span class="el-icon-upload"></span> {{$t("buttons.upload")}}</el-button>
    </span>
    </template>

  </el-dialog>
</template>

<script>
import FileMixin from "../../mixins/FileMixin";

export default {
  name: "CsvPreviewDialog",
  props: ["fileList"],
  mixins: [FileMixin],
  data(){
    return {
      showDialog: false,
      activeName: "0",
      csvObjects: [],
      csvHeaders: [],
      csvRemainingRecords: []
    }
  },
  methods:{
    confirmDialog(){
      this.$emit("submit");
      this.showDialog = false;
    },
    async show() {
      this.showDialog = true;
      this.activeName = "0"
      this.csvObjects= [];
      this.csvHeaders= [];
      this.csvRemainingRecords=[];
      //console.log(this.fileList)
      for (const file of this.fileList) {
        const text = await this.readTextFile(file.raw);
        const csvObj = await this.CSVToObj(text, ";");
        const headers = Object.keys(csvObj[0]);
        this.csvHeaders.push(headers)
        const sliced = csvObj.slice(0, 5);
        this.csvObjects.push(sliced);
        const remaining = csvObj.length-sliced.length;
        if (remaining>0){
          let last= {}
          headers.forEach(el =>  last[el] = `...` )
          //console.log(last)
          sliced.push(last)
        }
        this.csvRemainingRecords.push(remaining);
      }
    }
  }
}
</script>

<style scoped>

</style>