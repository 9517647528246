const getNewUserFields = (address = {}) => [
    {
        id: "email",
        label: "Email",
        type: "email",
        mandatory : true
    },
    {
        id: "name",
        label: "Nome",
        type: "text",
    }, {
        id: "surname",
        label: "Cognome",
        type: "text",
    }, {
        id: "businessName",
        label: "Ragione Sociale",
        type: "text"
    }, {
        id: "fiscalCode",
        label: "Codice Fiscale",
        type: "text"
    }, {
        id: "phoneNumber1",
        label: "Telefono 1",
        type: "text"
    }, {
        id: "phoneNumber2",
        label: "Telefono 2",
        type: "text"
    },
    ...getAddressFields(address, false),
    {
        id: "notes",
        label: "Note",
        type: "text",
    }, 
];

const getNewSimpleUserFields = () => [
    {
        id: "email",
        label: "Email",
        type: "email"
    },
    {
        id: "name",
        label: "Nome",
        type: "text",
    }, {
        id: "surname",
        label: "Cognome",
        type: "text",
    }
];

const getAddressFields = (values = {}, mandatory= true) => [{
    id: "address",
    label: "Indirizzo",
    type: "form-address",
    fields: {
        street: {
            label: "Via/Viale/Corso",
            value: values.street || ""
        },
        number: {
            label: "N.",
            value: values.number || ""
        },
        city: {
            label: "Città",
            value: values.city || ""
        },
        state: {
            label: "Provincia",
            value: values.state || ""
        },
        country: {
            label: "Paese",
            value: values.country || "Italia"
        },
        zip: {
            label: "CAP",
            value: values.zip || ""
        },
    },
    mandatory: mandatory
},]


const getNewCondominiumFields = (remoteMethod = null, condoAdminList= [], address = {}) => [
    {
        id: "owner",
        label: "Cliente Sunny",
        type: "dropdown",
        values: condoAdminList,
        remoteMethod: remoteMethod
    },
    {
        id: "condominiumAdministrator",
        label: "Amm. Cond.",
        type: "dropdown",
        values: condoAdminList,
        remoteMethod: remoteMethod
    },
    {
        id: "supplier",
        label: "Gestore Cal/Acq",
        type: "dropdown",
        values: condoAdminList,
        remoteMethod: remoteMethod
    },
    {
        id: "serviceTechnician",
        label: "Manutentore",
        type: "dropdown",
        values: condoAdminList,
        remoteMethod: remoteMethod
    },
    {
        id: "imgSource",
        label: "Logo da visualizzare",
        type: "simple-select",
        values: "",
        options:{
            "default" : "Default",
            "owner": "Cliente Sunny",
            "condominiumAdministrator" : "Amministratore Condominiale",
            "supplier": "Gestore Calore/Acqua",
            "serviceTechnician": "Ditta Assistenza Tecnica"
        },
        mandatory: true
    },
    {
        id: "description",
        label: "Denominazione",
        type: "text",
        mandatory: true
    },
    ...getAddressFields(address),
    {
        id: "fiscalCode",
        label: "Codice Fiscale",
        type: "text"
    },
    {
        id: "readingsStartDate",
        label: "Data Inizio Stagione",
        type: "seasonDate",
    },
    {
        id: "readingsEndDate",
        label: "Data Fine Stagione",
        type: "seasonDate",
    },
    {
        id: "transmissionType",
        label: "Tipo di trasmission",
        type: "dropdown",
        values: getTransmissionTypes(),
        remoteMethod: remoteMethod,
        mandatory: true
    },
];

const getTransmissionTypes = () => [
    {
        id: 0,
        value: "Chiaro",
        label: "Chiaro"
    },
    {
        id: 1,
        value: "Scuro",
        label: "Scuro"
    }
];

const getNewApartmentFields = () => [
    {
        id: "floor",
        label: "Piano / Scala",
        type: "text",
    },
    {
        id: "number",
        label: "Numero Appartamento",
        type: "text",
    },
    {
        id: "kApartment",
        label: "K appartamento",
        type: "number",
    },
    {
        id: "description",
        label: "Descrizione",
        type: "text",
    },
];
const getUpdatePasswordFields = () => [
    {
        id: "oldPassword",
        label: "Vecchia Password",
        type: "password"
    },
    {
        id: "newPassword",
        label: "Nuova Password",
        type: "password"
    }
]

const getNewMeterFields = (meterTypes = []) => [
    {
        id: "serialNumber",
        label: "Matricola",
        type: "text"
    },
    {
        id: "brand",
        label: "Marchio",
        type: "text"
    },
    {
        id: "meterType",
        label: "Tipologia",
        type: "dropdown",
        values: meterTypes
    },
    {
        id: "description",
        label: "Descrizione (Stanza)",
        type: "text"
    },
    {
        id: "kMeter",
        label: "ValoreK",
        type: "number"
    },
]

const getReplaceMeterFields = (meterTypes = []) => [
    {
        id: "serialNumber",
        label: "Matricola",
        type: "text"
    },
    {
        id: "brand",
        label: "Marchio",
        type: "text"
    },
    {
        id: "meterType",
        label: "Tipologia",
        type: "dropdown",
        values: meterTypes
    },
    {
        id: "description",
        label: "Descrizione (Stanza)",
        type: "text"
    },
    {
        id: "kMeter",
        label: "ValoreK",
        type: "number"
    },
    {
        id: "replacementDate",
        label: "Data Sostituzione",
        type: "date"
    },
]

export {getNewUserFields, getNewSimpleUserFields, getNewCondominiumFields, getAddressFields, getNewApartmentFields, getUpdatePasswordFields, getNewMeterFields, getReplaceMeterFields, getTransmissionTypes}
